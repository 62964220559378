// @ts-nocheck
import React from "react";
import LanguageModal from "./modals/Language";
import Confettie from "./modals/Confettie";
import PointModal from "./modals/Point";
import Lottery from "./modals/Lottery";
import Privacy from "./modals/Privacy";

const Modals = () => {
  return (
    <>
      <LanguageModal />
      <PointModal />
      <Lottery />
      <Confettie />
      <Privacy />
    </>
  );
};

export default Modals;
