// @ts-nocheck
import React from "react";
import Confettie from "react-confetti";
import { useConfettie } from "../useLanguage";

export const ConfettieProvider = () => {
  const { isConOpen } = useConfettie();

  if (!isConOpen) return null;

  return <Confettie style={{ zIndex: 10000, margin: "auto" }} />;
};
