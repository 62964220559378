// lib
import { toast } from "react-toastify";

let config = {
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: "colored",
};
export const toastError = (message) => {
  toast.error(message, config);
};

export const toastSuccess = (message) => {
  toast.success(message, config);
};

export const toastWarn = (message) => {
  toast.warn(message, config);
};

export const toastInfo = (message) => {
  toast.info(message, config);
};
