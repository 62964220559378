export const THAI = {
  home: "หลัก",
  mainBalance: "ยอดรวม",
  gameBalance: "ความสมดุลของเกม",
  setting: "การตั้งค่า",
  profile: "ข้อมูลส่วนตัว",
  friend: "เพื่อน",
  betslip: "สลิปพนัน",
  history: "ประวัติการเดิมพัน",
  cashIn: "เงินสดใน",
  cashOut: "เงินออก",
  logout: "ออกจากระบบ",
  login: "เข้าสู่ระบบ",
  gameCode: "รหัสเกม",
  turnOver: "หมุน เวียน",
  transferPlaceHolder: "กรุณาระบุจํานวนเงินที่ต้องการโอน",
  pleaseTransfer: "กรุณาโอน",
  searchGame: "ค้นหาที่นี่...",
  twoD: "2D",
  threeD: "3D",
  winNoHistory: "วินนัมเบอร์",
  moreGame: "มากกว่าเกมส์",
  playNow: "เล่นเลย",
  transfer: "ย้าย",
  apply: "ใช้",
  notice: "สังเกต",
  back: "กลับ",
  category: {
    newGame: "เกมใหม่",
    football: "ฟุตบอล",
    lottery: "หวย",
    cardGame: "เกมไพ่",
    liveCasino: "คาสิโนสด",
    fishingGame: "เกมส์ตกปลา",
    slotGame: "เกมสล็อต",
    promotion: "การส่งเสริม",
    blog: "บล็อก",
    other: "อื่นๆ",
  },
  profilePage: {
    level1: "บัญชีของคุณคือระดับ 1",
    level2: "บัญชีของคุณคือระดับ 2",
    upgradeLevel: "อัพเกรดระดับ",
    upgradeToLevel2: "อัปเกรดบัญชีของคุณเป็นระดับ 2",
    level2Image:
      "โปรดอัปโหลดภาพหน้าจอของ Wave Pay, KBZPay หรือ AYA Pay ระดับ 2",
    changePassword: "เปลี่ยนรหัสผ่าน",
    oldPassword: "รหัสผ่านเก่า",
    newPassword: "รหัสผ่านใหม่",
    userName: "ชื่อผู้ใช้",
    email: "อีเมล",
    phone: "โทรศัพท์",
    dateOfBirth: "วันเกิด",
    referralCode: "รหัสอ้างอิงของคุณ",
    yourReferralCode: "รหัสอ้างอิงของคุณ",
    editProfile: "แก้ไขโปรไฟล์",
    confirm: "ยืนยัน",
    send: "ส่ง",
  },
  referralPage: {
    yourFriend: "เพื่อนของคุณ",
    gameRefAmount: "จํานวนอ้างอิงเกม",
    claim: "อ้าง",
    name: "ชื่อ",
    date: "วันที่",
    section: "ตอน",
    amount: "ปริมาณ",
  },
  betslipPage: {
    twodSlips: "สลิป 2D",
    threedSlips: "สลิป 3D",
    cashinHistory: "ประวัติแคชชิน",
    cashoutHistory: "ประวัติแคชเอาต์",
    maintoGameBalanceHistory: "หลักสู่ประวัติศาสตร์ความสมดุลของเกม",
    gametoMainBalanceHistory: "เกมสู่ประวัติศาสตร์สมดุลหลัก",
    gameHistory: "ประวัติเกม",
    date: "วันที่",
    threeddate: "วันที่ 3D",
    section: "ตอน",
    totalAmount: "จํานวนเงินทั้งหมด",
    totalBetNumbers: "หมายเลขเดิมพันทั้งหมด",
    winAmount: "จํานวนเงินที่ชนะ",
    status: "สถานะ",
    paymentName: "ชื่อการชําระเงิน",
    accountHolderName: "ชื่อเจ้าของบัญชี",
    transationID: "รหัสการเปลี่ยน",
    oldBalance: "สมดุลเก่า",
    cashinAmount: "จํานวนเงินสด",
    cashoutAmount: "จํานวนแคชเอาต์",
    newBalance: "นิวบาลานซ์",
    winloss: "ชนะ/แพ้",
    commission: "คณะกรรมการ",
    profitloss: "กําไร/ขาดทุน",
    mainBalance: "ยอดรวม",
    transferAmount: "จํานวนเงินที่โอน",
    gameBalance: "ความสมดุลของเกม",
    referenceID: "รหัสอ้างอิง",
    validTurnover: "ผลประกอบการที่ถูกต้อง",
    userId: "รหัสผู้ใช้",
    gameType: "ประเภทเกม",
    stake: "เงินเดิมพัน",
  },
  cashInPage: {
    cashin: "เงินสดใน",
    holdername: "ชื่อผู้ถือ",
    transationID: "รหัสการเปลี่ยน",
    lastsixdigits: "ตัวเลข 6 หลักสุดท้าย",
    accountNumber: "หมายเลขบัญชี",
    amount: "ปริมาณ",
    transferToAmount: "กรุณาโอนไปยังบัญชีต่อไปนี้",
    request: "ขอร้อง",
    promoCode: "รหัสโปรโมชั่น",
    voucherCode: "Voucher Code",
  },
  cashOutPage: {
    cashout: "เงินออก",
    holdername: "ชื่อผู้ถือ",
    accountNumber: "หมายเลขบัญชี",
    amount: "ปริมาณ",
    request: "ขอร้อง",
  },
  loginPage: {
    signIn: "เข้าสู่ระบบ",
    phone: "โทรศัพท์",
    password: "รหัสผ่าน",
    registerAcc: "ลงทะเบียนบัญชี",
    forgetPassword: "ลืมรหัสผ่าน?",
  },
  registerPage: {
    userName: "ชื่อผู้ใช้",
    phone: "โทรศัพท์",
    password: "รหัสผ่าน",
    refferalCode: "รหัสอ้างอิง",
    register: "ลงทะเบียน",
    accountAlreadyExist: "มีบัญชีอยู่แล้ว?",
    changePhone: "เปลี่ยนโทรศัพท์",
    send: "ส่ง",
  },
  forgetPassPage: {
    phone: "โทรศัพท์",
    send: "ส่ง",
    password: "รหัสผ่าน",
    confirmPassword: "ยืนยันรหัสผ่าน",
    resetPassTitle: "ป้อนหมายเลขโทรศัพท์มือถือที่ลงทะเบียนของคุณ",
  },
  sectionTwoD: {
    lottery: "หวย",
    sportBook: "หนังสือกีฬา",
    liveCasino: "คาสิโนสด",
  },
  warning: {
    setAmount: "กรุณากําหนดจํานวนเงินก่อน",
    bettingClosed: "การเดิมพันถูกปิดแล้ว",
    lotteryClosed: "ไม่มีข้อมูลตัวเลขลอตเตอรีปิด?",
    needLevel2: "บัญชีของคุณต้องเป็นระดับ 2.",
    transferMainToGame: "กรุณาโอนอย่างน้อยหลักไปยังเกมหนึ่งครั้ง.",
    cannotClaim: "ไม่สามารถอ้างสิทธิ์ในจํานวนผู้อ้างอิงเกมน้อยกว่า 0 คนได้",
  },
  success: {
    cashInReq: "ส่งคําขอเงินสดแล้ว!",
    cashOutReq: "ส่งคําขอถอนเงินแล้ว!",
    successChangePass: "รหัสผ่านของคุณได้รับการเปลี่ยนแปลงเรียบร้อยแล้ว!",
    verifyCodeSent: "ส่งรหัสยืนยันแล้ว!",
  },
  winHistoryPage: {
    luckyNo: "เลขนำโชค",
    section: "ตอน",
    date: "วันที่",
  },
  winnerListHistory: {
    title: "รายชื่อผู้ชนะ",
    section: "ตอน",
    lucky_number: "เลขนำโชค",
    no: "เลขที่",
    phone: "หมายเลขโทรศัพท์",
    bet_amount: "จำนวนเงินเดิมพัน",
    win_amount: "จำนวนเงินที่ชนะ",
  },
};
